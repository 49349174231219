/* eslint-disable react-hooks/exhaustive-deps */
import { collection, onSnapshot, getFirestore, QuerySnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Grid } from "@mui/material";

const formatTimeStamp = (timestamp: any) => new Date(timestamp.toDate()).toString()

const Admin = () => {
  const [snapshot, setSnapshot] = useState<QuerySnapshot>();
  const [allData, setAllData] = useState<any[]>([]);
  const columns: GridColDef[] = [
    { field: "serial", headerName: "Serial" },
    { field: "name", headerName: "Name" },
    { field: "mobile", headerName: "Mobile" },
    { field: "city", headerName: "City" },
    { field: "workplace", headerName: "Workplace" },
    { field: "registeredAt", headerName: "Registered At"  },
  ];
  const rows: GridRowsProp = allData.map((row, index) => ({
    id: row.id,
    serial: index + 1,
    name: row.name,
    mobile: row.mobile,
    city: row.city,
    workplace: row.workplace,
    registeredAt: row.registeredAt,
  }));

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(getFirestore(), "guests"), (snapshot) => {
        setSnapshot(snapshot)
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
      if(snapshot) {
        if(allData.length) {
            snapshot.docChanges().forEach(change => {
                if(change.type === 'added') {
                    const newDoc = {id: change.doc.id, ...change.doc.data(), registeredAt: formatTimeStamp(change.doc.data().registeredAt)}
                    setAllData([...allData, {...newDoc}])
                }
            })
        } else {
            // set for first time
            setAllData(snapshot.docs.map(doc => ({
                ...doc.data(),
                registeredAt: formatTimeStamp(doc.data().registeredAt),
                id: doc.id
            })))
        }
      }
  }, [snapshot])

  return (
    <Grid container height="80vh" gap={4}>
        <DataGrid pagination columns={columns} rows={rows} components={{ Toolbar: GridToolbar }} />
    </Grid>
  );
};

export default Admin;
