import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { ChangeEvent, useState, useEffect } from "react";
import Person from "@mui/icons-material/Person";
import LocationCity from "@mui/icons-material/LocationCity";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";
import { cities } from "./constants";
import PhoneIphone from "@mui/icons-material/PhoneIphone";
import { Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { firebaseConfig } from "./constants";
import { useSnackbar } from "notistack";

import { initializeApp } from "firebase/app";
import { collection, addDoc, serverTimestamp, getFirestore } from "firebase/firestore";

interface IForm {
  name: string;
  mobile: string;
  city: string;
  workplace: string;
}

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

const App = () => {
  const { enqueueSnackbar } = useSnackbar();

  const formInitialValues: IForm = {
    name: "",
    mobile: "",
    city: "",
    workplace: "",
  };
  const [form, setForm] = useState<IForm>(formInitialValues);
  const [selectedCity, setSelectedCity] = useState<{
    id: string;
    name_ar: string;
    name_en: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);

  const formChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e.target.name;
    const value = e.target.value;
    setForm({ ...form, [field]: value });
  };

  const isFormValid = Object.values(form).every((value) => !!value);

  const registerHandler = async () => {
    if (isFormValid) {
      setLoading(true);
      try {
        await addDoc(collection(db, "guests"), {
          ...form,
          registeredAt: serverTimestamp(),
        });
      } catch (e) {
        console.log(e)
        enqueueSnackbar("Error registering guest. Check internet connection!", {
          variant: "error",
        });
        setLoading(false);
      } finally {
        enqueueSnackbar("Guest registered successfully!", { variant: "success" });
        setLoading(false);
        setForm(formInitialValues);
        setSelectedCity(null);
      }
    }
  };

  useEffect(() => {
    setForm({ ...form, city: selectedCity ? selectedCity.name_en : "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity]);

  return (
    <Grid
      container
      gap={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="70vw"
      margin="0 auto"
    >
      <Grid component={Paper} gap={3} direction="column" padding={2} container>
        <TextField
          autoComplete="new-password"
          placeholder="Name"
          type="text"
          name="name"
          label="Name"
          value={form.name}
          onChange={formChangeHandler}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <TextField
          autoComplete="new-password"
          placeholder="Mobile"
          type="text"
          name="mobile"
          label="Mobile"
          value={form.mobile}
          onChange={formChangeHandler}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIphone />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        <Autocomplete
          disablePortal
          value={selectedCity}
          options={cities}
          getOptionLabel={(option) => `${option.name_en} - ${option.name_ar}`}
          onChange={(_, city) => city && setSelectedCity(city)}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
              }}
              label="City"
              name="city"
              placeholder="City"
            />
          )}
        />
        <TextField
          autoComplete="new-password"
          placeholder="Workplace"
          type="text"
          name="workplace"
          label="Workplace"
          value={form.workplace}
          onChange={formChangeHandler}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationCity />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Grid>
      <Button
        fullWidth
        variant="contained"
        disabled={!isFormValid || loading}
        onClick={registerHandler}
      >
        <Typography sx={{ paddingRight: "10px" }}>Register</Typography>{" "}
        {loading && <CircularProgress color="inherit" size={20} />}
      </Button>
    </Grid>
  );
};

export default App;
