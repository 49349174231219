import Grid from "@mui/material/Grid";

const NotFound = () => {
  return (
    <Grid
      container
      gap={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="70vw"
      margin="0 auto"
    >
      <h1>404 - Not Found</h1>
    </Grid>
  );
};

export default NotFound;
